import { useRoute } from 'vue-router';
import { useCookie } from 'nuxt/app';

export default () => {
  const route = useRoute();

  const pageqsCookie = useCookie('pageqs', {
    maxAge: 60 * 60 * 24 * 30,
    default: () => null,
  });

  const referrerCookie = useCookie('referrer', {
    maxAge: 60 * 60 * 24,
    default: () => null,
  });

  if (import.meta.client) {
    if (!pageqsCookie.value && route.query.utm_source === 'affiliate') {
      const { maxAge, ...queryWithoutMaxAge } = route.query;
      pageqsCookie.value = queryWithoutMaxAge;
    }
    if (!referrerCookie.value) {
      referrerCookie.value = document.referrer || '';
    }
  }
};
