<template>
  <div>
    <Notifications :mapped-store="notieStore" />
    <Header />
    <div>
      <slot />
    </div>
    <Chat v-if="chatStore.isChatAvailable" />
    <LazyFooter />
  </div>
</template>

<script setup>
import pageqs from '@/helpers/pageqs.js';

const { $ws } = useNuxtApp();
const route = useRoute();
const clickStore = useClickStore();
const notieStore = useNotieStore();
const chatStore = useChatStore();

await useAsyncData('click', async () => {
  await clickStore.fetchCrmClickId();
  return { success: true };
});

watch(
  () => route.name,
  () => {
    if (import.meta.client) {
      clickStore.fetchCrmClickId();
    }
  },
);

onMounted(async () => {
  pageqs();
  clickStore.fetchCrmClickId();

  if (!$ws.sockets.chat) {
    await $ws.useConnection();
  }
});
</script>
